


































































































































































import Vue from 'vue'
import Component from 'vue-class-component';
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import Widget from '@/components/common/Widget.vue';
import DataTable from '@/components/table/DataTable.vue';
import store from '../../store';
import api from '../../api';
import { Watch } from 'vue-property-decorator';


declare const $:any;

@Component({components: {Breadcrumb, Widget, DataTable}})
export default class AgencySettings extends Vue
{
  tableColums = [
    {
      label: "ID",
      prop: "id",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "E-Mail Adresse",
      prop: "username",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Vorname",
      prop: "firstname",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Nachname",
      prop: "lastname",
      bodyCellClass: 'text-left',
      sortable: true
    },
    {
      label: "Level",
      prop: "level",
      bodyCellClass: 'text-left',
      sortable: true
    }
  ]
  perPage = 25;
  orderby = "id";
  orderDirection = "asc";
  totalItems = 0;
  offsetPage = 0;
  tableRows: any[] = []
  user: any = {}
  userId = ''
  loadedAgencies: any = {}
  agencies : any[] = []
  // agency = ''
  clients : any[] = []
  client : any = {}
  filteredRows: any[] = []
  previousMNumber = ''
  // agency= ''
  selectEmployeesAgency: any[] = []
  selectedEmployee= ''
  selectAssignedEmployeesAgency: any[] = []
  selectedAssignedEmployee= ''
  selectedClientToUnassign= ''

  constructor() {
    super()
    this.getAgencies()
  }

  mounted() {
    this.getMandators()
    this.getEmployees()
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) {
        this.clearOnEsc()
      }
    })
  }

  get agency() {
      return store.getters.selectedAgency
  }
  getAgencies(){
    api.getConfigClientMandators().then(res => {
      this.loadedAgencies = res.data
      let getAgencies = Object.keys(res.data)
      getAgencies.sort()
      this.agencies   = getAgencies


      let selectedAgency = getAgencies[0]
      
      if(this.agency == null){
        store.dispatch('updateSelectedAgency', selectedAgency)
      }else{
        selectedAgency = this.agency
      }

      this.clients    = this.loadedAgencies[selectedAgency]
      this.client     = this.clients[0] || ''
      store.dispatch('updateLoading', store.state.loading - 1)
    }).catch(err => {
      store.dispatch('updateLoading', store.state.loading - 1)
    })
  }

  clearOnEsc() {
      //A way to handle Jquery component inside Vuejs
      const _bodySelector =  document.querySelector('body')
      if(_bodySelector){
      _bodySelector.click()
      }
  }
  setMandator(client: any) {
      localStorage.setItem('client', JSON.stringify(client))
      this.client = client
  }
  setAgency(agency: any) {
      localStorage.setItem('agency', JSON.stringify(agency))
      store.dispatch('updateSelectedAgency', agency)
  }

  @Watch('agency')
  onAgencyChange() {
    if(this.agency==null){
      return;
    }
    let selectedAgency = this.agency
    this.clients    = this.loadedAgencies[selectedAgency]
    this.client     = this.clients[0] || ''
    this.getEmployees()

  }
  @Watch('client')
  onClientChange() {
    this.getMandators()
  }

   getEmployees(){
    if(this.agency == null){
      return null
    }
    api.getEmployees(this.agency).then(res => {
      // let getAgencies = Object.keys(res.data)
      // this.agencies   = getAgencies
      let filtredByLevel = res.data.filter((itm:any) => (itm[4] == 2))
      this.selectEmployeesAgency = filtredByLevel
    })
  }

  unassignEmployee(event:any){
    this.selectedClientToUnassign = event.id
    // store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
    // api.getMandatorEmployees(this.agency,event.mnumber).then(res => {
    //   // let getAgencies = Object.keys(res.data)
    //   // this.agencies   = getAgencies
    //   // let filtredByLevel = res.data.filter((itm) => itm[4] == 3)
    //   this.selectAssignedEmployeesAgency = res.data
    //   store.dispatch('updateLoading', store.state.loading - 1)

    // }).catch(err => {
    //   store.dispatch('updateLoading', store.state.loading - 1)
    // })
    $('#unassign_modal').modal()
  }

  getMandatorEmployees() {
    if (this.client) {
      store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
      api.getMandatorEmployees(this.client[0], this.client[1]).then(res => {
        store.dispatch('updateLoading', store.state.loading - 1)
      }).catch(err => {
        store.dispatch('updateLoading', store.state.loading - 1)
      })
    }
  }

  

  getMandators() {
    if (this.agency) {
      this.tableRows = []

      store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)

      api.getMandatorEmployees(this.agency,this.client.mnumber).then(res => {
        store.dispatch('updateLoading', store.state.loading - 1)
        res.data.forEach((d: any) => {
          if(d['4'] == 1 || d['4'] == 2){
            this.tableRows.push({
              id: d[0],
              username: d[1],
              firstname: d[2],
              lastname: d[3],
              level: d[4],
            })
          }
        })
        this.filteredRows = this.tableRows
      }).catch(err => {
        store.dispatch('updateLoading', store.state.loading - 1)
      })
    }
  }

  addModal() {
    $('#assign_modal').modal()
  }

  edit(event: any) {
    this.user = event
    this.userId = event.id
    this.previousMNumber = this.user.mnumber
    $('#add_modal').modal()
  }

  changePermission(event: any) {
    this.user = event
    this.userId = event.id
    $('#permission_modal').modal()
  }

  deleteItem(event: any) {
    store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
    api.deleteMandator(this.agency, event.mnumber).then(res => {
      store.dispatch('updateLoading', store.state.loading - 1)
      this.getMandators()
    }).catch(err => {
      store.dispatch('updateLoading', store.state.loading - 1)
    })
  }

  assignEmployee(event: any) {
    this.user = event
    $('#assign_modal').modal()
  }

  assignClientToAgency(){
    //linkEmployeeMandator
    api.linkEmployeeMandator(this.agency,this.client.mnumber,this.selectedEmployee).then(res => {
      // let getAgencies = Object.keys(res.data)
      // this.agencies   = getAgencies
      this.getMandators()
    })
  }
  unassignClientToAgency(){
    api.unlinkEmployeeMandator(this.agency,this.client.mnumber,this.selectedClientToUnassign).then(res => {
      this.getMandators()
    })
  }

  add() {
    if (this.userId) {
      store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
      api.updateMandator(this.agency, this.previousMNumber, this.user).then(res => {
        store.dispatch('updateLoading', store.state.loading - 1)
        this.getMandators()
      }).catch(err => {
        store.dispatch('updateLoading', store.state.loading - 1)
      })
    } else {
      store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
      api.postMandator(this.agency, this.user).then(res => {
        store.dispatch('updateLoading', store.state.loading - 1)
        this.getMandators()
      }).catch(err => {
        store.dispatch('updateLoading', store.state.loading - 1)
      })
    }
  }

  toggle() {
    store.dispatch('updateLoading', store.state.loading >= 0 ? store.state.loading + 1 : 1)
    api.toggleMandator(this.agency, this.user.mnumber, this.user.active).then(res => {
      store.dispatch('updateLoading', store.state.loading - 1)
      this.getMandators()
    }).catch(err => {
      store.dispatch('updateLoading', store.state.loading - 1)
    })
  }

  search(event:any) {
    if (event) {
      this.filteredRows = this.tableRows.filter(row => row.name.toLowerCase().includes(event.toLowerCase()) 
        || (row.agency && row.agency.toLowerCase().includes(event.toLowerCase())))
    } else {
      this.filteredRows = this.tableRows
    }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = 'asc';
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
      }
    }
  }

}
